import { configureStore, createSlice, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Action } from 'redux'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import { userSlice } from '@/features/user/userSlice'
import { tyreSizesApi } from '@/api/tyreSizes'
import { countriesApi } from '@/api/countries'
import { currenciesApi } from '@/api/currencies'
import { appSlice } from '@/features/app/appSlice'
import { colorsApi } from '@/api/colors'
import { sessionApi } from '@/api/session'
import { snackbarSlice } from '@/features/snackbar/snackbarSlice'
import { highlightsApi } from '@/api/highlights'
import { instagramFeedApi } from '@/api/instagramFeed'
import { deliveryAddressesApi } from '@/api/deliveryAddresses'
import { orderApi } from '@/api/order'
import { contactApi } from '@/api/contact'
import { articleApi } from '@/api/article'
import { vehicleSlice } from '@/features/vehicle/vehicleSlice'
import { invoiceApi } from '@/api/invoice'
import { receiptApi } from '@/api/receipt'
import { resetPasswordApi } from '@/api/resetPassword'
import { searchSlice } from '@/features/search/searchSlice'
import { baseApi } from '@/api/baseApi'
import { wheelsSlice } from '@/features/wheels/wheelsSlice'

const makeStore = (context: any) =>
  configureStore({
    reducer: {
      // Slice Reducers
      [appSlice.name]: appSlice.reducer,
      [userSlice.name]: userSlice.reducer,
      [snackbarSlice.name]: snackbarSlice.reducer,
      [vehicleSlice.name]: vehicleSlice.reducer,
      [searchSlice.name]: searchSlice.reducer,
      [wheelsSlice.name]: wheelsSlice.reducer,

      // Api Reducers
      [baseApi.reducerPath]: baseApi.reducer,
      [tyreSizesApi.reducerPath]: tyreSizesApi.reducer,
      [countriesApi.reducerPath]: countriesApi.reducer,
      [currenciesApi.reducerPath]: currenciesApi.reducer,
      [colorsApi.reducerPath]: colorsApi.reducer,
      [sessionApi.reducerPath]: sessionApi.reducer,
      [highlightsApi.reducerPath]: highlightsApi.reducer,
      [instagramFeedApi.reducerPath]: instagramFeedApi.reducer,
      [deliveryAddressesApi.reducerPath]: deliveryAddressesApi.reducer,
      [orderApi.reducerPath]: orderApi.reducer,
      [contactApi.reducerPath]: contactApi.reducer,
      [articleApi.reducerPath]: articleApi.reducer,
      [invoiceApi.reducerPath]: invoiceApi.reducer,
      [receiptApi.reducerPath]: receiptApi.reducer,
      [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            cookies: context?.req?.headers?.cookie
          },
        },
      })
        .concat([
          baseApi.middleware,
          tyreSizesApi.middleware,
          countriesApi.middleware,
          currenciesApi.middleware,
          colorsApi.middleware,
          sessionApi.middleware,
          highlightsApi.middleware,
          instagramFeedApi.middleware,
          deliveryAddressesApi.middleware,
          orderApi.middleware,
          contactApi.middleware,
          articleApi.middleware,
          invoiceApi.middleware,
          receiptApi.middleware,
          resetPasswordApi.middleware,
        ]),
    devTools: true,
  })

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>

export type AppDispatch = ThunkDispatch<RootState, unknown, Action>;

export const wrapper = createWrapper<AppStore>(makeStore)