import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createSlice } from '@reduxjs/toolkit'
import { RimSearchCriteraModel, TyreSearchModel } from '@/types/api'
import { SelectedItem } from '@/utils/hooks/useFilter'

// Type for our state
export interface RimsState {
  rimsCriteria?: RimSearchCriteraModel
  tyresCriteria?: TyreSearchModel,
  tyreFilter: SelectedItem[]
  rimFilter: SelectedItem[]
}

// Initial state
const initialState: RimsState = {
  tyreFilter: [],
  rimFilter: []
}

// Actual Slice
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setRimsCriteria: (state, action) => {
      state.rimsCriteria = action.payload
      state.rimFilter = []
    },
    resetRimDestination: (state) => {
      if (state.rimsCriteria)
        state.rimsCriteria.description = ''
    },
    setTyresCriteria: (state, action) => {
      state.tyresCriteria = action.payload
      state.tyreFilter = []
    },
    setTyreFilter: (state, action) => {
      state.tyreFilter = action.payload
    },
    setRimFilter: (state, action) => {
      state.rimFilter = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.search,
      }
    })
  },
})

export const {
  setRimsCriteria,
  setTyresCriteria,
  setTyreFilter,
  setRimFilter,
  resetRimDestination
} = searchSlice.actions

export const selectRimsCriteria = (state: RootState) => state.search.rimsCriteria
export const selectTyresCriteria = (state: RootState) => state.search.tyresCriteria
export const selectTyreFilter = (state: RootState) => state.search.tyreFilter
export const selectRimFilter = (state: RootState) => state.search.rimFilter

export default searchSlice.reducer