import '@/styles/globals.scss'
import '@/styles/flexboxgrid.scss'
import type { AppProps } from 'next/app'
import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { wrapper } from '../store/store'
import { appWithTranslation } from 'next-i18next'
import { Exo } from '@next/font/google'
import SnackbarManager from '@/features/snackbar/SnackbarManager/SnackbarManager'
import NextNProgress from 'nextjs-progressbar'
import SessionManager from '@/components/common/SessionManager/SessionManager'

const exo = Exo({
  subsets: ['latin'],
})

const App: FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest)
  return (
    <Provider store={store}>
      <main className={exo.className}>
        <NextNProgress color="#F87C18" />
        <Component {...props.pageProps} />
        <SnackbarManager />
        <SessionManager />
      </main>
    </Provider>
  )
}

export default appWithTranslation(App)